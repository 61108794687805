import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38')
];

export const server_loads = [0,3];

export const dictionary = {
		"/(auth)": [~15,[2]],
		"/dashboard": [24,[3]],
		"/dashboard/comment-reagir": [25,[3]],
		"/dashboard/messages": [26,[3,4]],
		"/dashboard/messages/defend": [27,[3,4,5]],
		"/dashboard/messages/defend/authority": [~28,[3,4,5,6]],
		"/dashboard/notifications": [~29,[3,7]],
		"/dashboard/parametres": [30,[3,8]],
		"/dashboard/proches": [~31,[3,9]],
		"/dashboard/profil": [~32,[3,10]],
		"/finaliser": [33,[11]],
		"/formule": [~34,[12]],
		"/inviter": [~35,[13]],
		"/kyc/success": [~36],
		"/(auth)/login": [~16,[2]],
		"/(auth)/login/reset": [17,[2]],
		"/(auth)/login/restore": [~18,[2]],
		"/(auth)/logout": [~19,[2]],
		"/(auth)/parent-authorization": [~20,[2]],
		"/payment/success": [~37],
		"/reseaux-sociaux": [~38,[14]],
		"/(auth)/signup": [~21,[2]],
		"/(auth)/signup/activation": [~22,[2]],
		"/(auth)/signup/missing-fields": [~23,[2]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';